import { IApiDataType } from '../types'
import {
  api as burgerworksApi,
  configs as burgerworksConfigs,
} from './burgerworks'
import useArbListLocationGroupPrimantiTattleKpis, {
  arbListLocationGroupPrimantiTattleKpisConfigs,
} from './useArbListLocationGroupPrimantiTattleKpis'
import useArbListLocationPrimantiTattleKpis, {
  arbListLocationPrimantiTattleKpisConfigs,
} from './useArbListLocationPrimantiTattleKpis'
import useLfrBusinessReviewKpis, {
  lfrBusinessReviewKpisConfigs,
} from './useLfrBusinessReviewKpis'
import useMadeFromScratchSummary, {
  madeFromScratchSummaryConfigs,
} from './useMadeFromScratchSummary'
import useSystemwideOverviewKpis, {
  systemwideOverviewKpisConfigs,
} from './useSystemwideOverviewKpis'

export type IApiKeyType = keyof typeof api

export const configs = {
  ...burgerworksConfigs,
  lfrBusinessReviewKpis: lfrBusinessReviewKpisConfigs,
  systemwideOverviewKpis: systemwideOverviewKpisConfigs,
  arbListLocationGroupPrimantiTattleKpis:
    arbListLocationGroupPrimantiTattleKpisConfigs,
  arbListLocationPrimantiTattleKpis: arbListLocationPrimantiTattleKpisConfigs,
  madeFromScratchSummary: madeFromScratchSummaryConfigs,
}

const api = {
  ...burgerworksApi,
  lfrBusinessReviewKpis: useLfrBusinessReviewKpis,
  systemwideOverviewKpis: useSystemwideOverviewKpis,
  arbListLocationGroupPrimantiTattleKpis:
    useArbListLocationGroupPrimantiTattleKpis,
  arbListLocationPrimantiTattleKpis: useArbListLocationPrimantiTattleKpis,
  madeFromScratchSummary: useMadeFromScratchSummary,
}

const useApi = (
  apiName: IApiKeyType,
): { data: IApiDataType; loading: boolean } => api[apiName]()

export default useApi
