import COLORS from 'pared/constants/colors'

import { IConfigsType } from '../../types'

const wingitnorthConfig: IConfigsType = {
  wingitnorth: {
    '/:brand/systemwide_overview': {
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      systemwideOverview: {
        type: 'list',
        api: 'systemwideOverviewKpis',
        title: 'Systemwide Overview',
        width: '120px',
        fields: [
          {
            key: 'netSales',
            title: 'Total Sales',
          },
          {
            key: 'netSalesSssPercent',
            title: 'SSS %',
            decimal: 1,
          },
          {
            key: 'netSalesBudgetVariance',
            title: 'Net Sales Budget Variance',
          },
          {
            key: 'checkCount',
            title: 'Check Count',
          },
          {
            key: 'checkCountVsLyPercent',
            title: 'Check Count % vs LY',
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            decimal: 2,
          },
          {
            key: 'hourlyLaborCostPercent',
            title: 'Labor Percent',
            decimal: 1,
          },
          {
            key: 'hourlyLaborBudgetVariance',
            title: 'Labor Budget Variance',
            decimal: 1,
          },
          {
            key: 'avtPercent',
            title: 'AvT',
            decimal: 1,
          },
          {
            key: 'cogsPercent',
            title: 'COGS',
            decimal: 1,
          },
          {
            key: 'cogsBudgetVariance',
            title: 'COGS Budget Variance',
            decimal: 1,
          },
        ],
      },
      breakdownByStore: {
        type: 'table',
        api: 'systemwideOverview',
        title: 'Breakdown by Store',
        pageSize: 20,
        groupInfo: {
          width: '200px',
          groupBy: {
            width: '200px',
          },
        },
        columns: [
          {
            key: 'Sales',
            title: 'Net Sales',
            align: 'center',
            highlight: true,
            children: [
              {
                key: 'netSales',
                title: '$',
                align: 'center',
                sortable: true,
              },
              {
                key: 'netSalesBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
              },
              {
                key: 'netSalesSssPercent',
                title: 'SSS %',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'Labor',
            title: 'Labor',
            align: 'center',
            children: [
              {
                key: 'hourlyLaborCostPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'hourlyLaborBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
          {
            key: 'COGS',
            title: 'COGS',
            align: 'center',
            children: [
              {
                key: 'cogsPercent',
                title: '%',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'cogsBudgetVariance',
                title: 'Variance',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
              {
                key: 'avtPercent',
                title: 'AvT',
                align: 'center',
                sortable: true,
                decimal: 1,
              },
            ],
          },
        ],
      },
      revenueCenterAndDayPart: {
        type: 'grid',
        children: {
          revenueCenter: {
            type: 'table-v2',
            api: 'locationGroupRevenueCenter',
            title: 'Revenue Center',
            columns: [
              {
                key: 'name',
                title: 'Revenue Center',
                sortable: true,
              },
              {
                key: 'sales',
                title: 'Net Sales',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'percentSales',
                title: '% Net Sales',
                sortable: true,
              },
              {
                key: 'checkCount',
                title: 'Check Count',
                sortable: true,
              },
              {
                key: 'checkAverage',
                title: 'Check Average',
                unit: 'dollar',
                decimal: 2,
                sortable: true,
              },
            ],
          },
          dayPart: {
            type: 'table-v2',
            api: 'locationGroupDayPart',
            title: 'Day Part',
            columns: [
              {
                key: 'name',
                title: 'Day Part',
                sortable: true,
              },
              {
                key: 'sales',
                title: 'Sales',
                unit: 'dollar',
                sortable: true,
              },
              {
                key: 'percentSales',
                title: '% Net Sales',
                sortable: true,
              },
              {
                key: 'checkCount',
                title: 'Check Count',
                sortable: true,
              },
              {
                key: 'checkAverage',
                title: 'Check Average',
                unit: 'dollar',
                decimal: 2,
                sortable: true,
              },
            ],
          },
        },
      },
      map: {
        type: 'map',
        api: 'listGeoInfo',
      },
    },
  },
}

export default wingitnorthConfig
