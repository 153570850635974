import { useVariables } from '../../../variables'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const trendLocationGroupLossPreventionConfigs = {
  date: 'string',
  lossPreventionCompsVoids: 'price',
  lossPreventionCompsDeletes: 'price',
  lossPreventionCompsOverrings: 'price',
  lossPreventionCompsRefunds: 'price',
} as const

const useTrendLocationGroupLossPreventions = () => {
  const { variables } = useVariables()
  const dateRange = variables.lastTimeRange?.value[0][0] || ''
  const { data: commonData, loading } = buildMetricValuesHook(
    {
      groups: ['Expo Module - Loss Prevention Comps'],
    },
    dateRange,
  )()

  return {
    data: commonData,
    loading,
  }
}

export default useTrendLocationGroupLossPreventions
