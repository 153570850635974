import calc from '../../../utils/calc'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

export const burgerworksListSystemwideOverviewKpisConfigs = {
  netSales: 'price',
  checkCount: 'number',
  checkAverage: 'price',
  netSalesSssPercent: 'price',
  laborTotalCostPercent: 'percent',
} as const

const useBurgerworksListSystemwideOverviewKpis = buildMetricValuesHook(
  {
    groupFilterType: 'corporate',
    metrics: {
      codes: [
        'net_sales',
        { key: 'net_sales', type: 'yoy' },
        'check_count',
        'check_average',
        'labor_total_cost_percent',
      ],
    },
  },
  (data) => ({
    ...data,
    netSalesSssPercent:
      calc(
        calc(calc(data.netSales, '-', data.yoyNetSales), '/', data.yoyNetSales),
        '*',
        100,
      ) || null,
  }),
)

export default useBurgerworksListSystemwideOverviewKpis
