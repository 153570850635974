import useItem, { itemConfigs } from './useItem'
import useItemByDaypart, { itemByDaypartConfigs } from './useItemByDaypart'
import {
  itemWowGrowthByQuantityConfigs,
  useBottomItemWowGrowthByQuantity,
  useTopItemWowGrowthByQuantity,
} from './useItemWowGrowthByQuantity'
import {
  itemWowGrowthBySalesConfigs,
  useBottomItemWowGrowthBySales,
  useTopItemWowGrowthBySales,
} from './useItemWowGrowthBySales'
import useLocationGroupDayPart, {
  locationGroupDayPartConfigs,
} from './useLocationGroupDayPart'
import useLocationGroupRevenueCenter, {
  locationGroupRevenueCenterConfigs,
} from './useLocationGroupRevenueCenter'
import useLocationGroups, { locationGroupsConfigs } from './useLocationGroups'
import useLocations, { locationsConfigs } from './useLocations'
import useMadeFromScratch, {
  madeFromScratchConfigs,
} from './useMadeFromScratch'
import useMadeFromScratchSummary, {
  madeFromScratchSummaryConfigs,
} from './useMadeFromScratchSummary'

export const configs = {
  locationGroups: locationGroupsConfigs,
  locations: locationsConfigs,
  itemByDaypart: itemByDaypartConfigs,
  madeFromScratch: madeFromScratchConfigs,
  madeFromScratchSummary: madeFromScratchSummaryConfigs,
  locationGroupDayPart: locationGroupDayPartConfigs,
  locationGroupRevenueCenter: locationGroupRevenueCenterConfigs,
  topItemWowGrowthByQuantity: itemWowGrowthByQuantityConfigs,
  bottomItemWowGrowthByQuantity: itemWowGrowthByQuantityConfigs,
  topItemWowGrowthBySales: itemWowGrowthBySalesConfigs,
  bottomItemWowGrowthBySales: itemWowGrowthBySalesConfigs,
  item: itemConfigs,
}

export const api = {
  locationGroups: useLocationGroups,
  locations: useLocations,
  itemByDaypart: useItemByDaypart,
  madeFromScratch: useMadeFromScratch,
  madeFromScratchSummary: useMadeFromScratchSummary,
  locationGroupDayPart: useLocationGroupDayPart,
  locationGroupRevenueCenter: useLocationGroupRevenueCenter,
  topItemWowGrowthByQuantity: useTopItemWowGrowthByQuantity,
  bottomItemWowGrowthByQuantity: useBottomItemWowGrowthByQuantity,
  topItemWowGrowthBySales: useTopItemWowGrowthBySales,
  bottomItemWowGrowthBySales: useBottomItemWowGrowthBySales,
  item: useItem,
}
