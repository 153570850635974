import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiType } from '../../types'

const NUM_OF_TOP_BOTTOM = 5

const query = gql`
  query trendLocationGroupItemMetrics(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    trendLocationGroupItemMetrics(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: "business_week"
    ) {
      nodes {
        locationGroupId
        locationGroupName
        startDate
        endDate
        itemCode
        itemName
        categoryName
        quantitySold
        businessWeek
        businessWeekOfMonth
        businessMonth
        businessQuarter
        businessYear
      }
    }
  }
`

export const itemWowGrowthByQuantityConfigs = {
  locationGroupId: 'number',
  locationGroupName: 'string',
  startDate: 'string',
  endDate: 'string',
  itemCode: 'string',
  itemName: 'string',
  categoryName: 'string',
  quantitySold: 'number',
  wowGrowth: 'percent',
  quantityLastWeek1: 'number',
  quantityLastWeek2: 'number',
  quantityLastWeek3: 'number',
  quantityLastWeek4: 'number',
  businessWeek: 'number',
  businessWeekOfMonth: 'number',
  businessMonth: 'number',
  businessQuarter: 'number',
  businessYear: 'number',
} as const

const useItemWowGrowthByQuantity = (type: 'top' | 'bottom'): IApiType => {
  const { startDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const iFilter = {
    location_group_ids: groupFilter?.ids,
    metrics: ['quantity_sold'],
  }

  const queryStartDate = startDate ? new Date(startDate) : new Date()
  queryStartDate.setDate(queryStartDate.getDate() - 28)
  const queryEndDate = startDate ? new Date(startDate) : new Date()
  queryEndDate.setDate(queryEndDate.getDate() - 1)

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: queryStartDate.toISOString().split('T')[0],
      iEndDate: queryEndDate.toISOString().split('T')[0],
      iFilter,
    },
    skip: !startDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const nodes = data?.trendLocationGroupItemMetrics?.nodes as
        | {
            locationGroupId: number
            locationGroupName: string
            startDate: string
            endDate: string
            itemCode: string
            itemName: string
            categoryName: string
            quantitySold: number | null
            businessWeek: number
            businessWeekOfMonth: number
            businessMonth: number
            businessQuarter: number
            businessYear: number
          }[]
        | undefined

      if (!nodes) return null

      const allWeeks = Array.from(new Set(nodes.map((node) => node.startDate)))
        .map((dateStr) => new Date(dateStr))
        .sort((a, b) => a.getTime() - b.getTime())

      const lastFourWeeksDates = allWeeks.slice(-4)
      const lastFourWeeksStrings = lastFourWeeksDates.map(
        (date) => date.toISOString().split('T')[0],
      )

      const itemMap = new Map<
        string,
        {
          earliest: typeof nodes[0]
          latest: typeof nodes[0]
          quantitiesPerWeek: Map<string, number>
        }
      >()

      nodes.forEach((node) => {
        const key = node.itemName
        const dateStr = node.startDate
        const current = itemMap.get(key)
        if (!current) {
          const quantitiesPerWeek = new Map<string, number>()
          quantitiesPerWeek.set(dateStr, node.quantitySold ?? 0)
          itemMap.set(key, {
            earliest: node,
            latest: node,
            quantitiesPerWeek,
          })
        } else {
          const earliestDate = new Date(current.earliest.startDate)
          const latestDate = new Date(current.latest.startDate)
          const nodeDate = new Date(node.startDate)
          if (nodeDate < earliestDate) current.earliest = node
          if (nodeDate > latestDate) current.latest = node
          const existingQuantity = current.quantitiesPerWeek.get(dateStr) || 0
          current.quantitiesPerWeek.set(
            dateStr,
            existingQuantity + (node.quantitySold ?? 0),
          )
        }
      })

      const result = Array.from(itemMap.values()).map(
        ({ earliest, latest, quantitiesPerWeek }) => {
          const validWeeks = lastFourWeeksStrings.map((week, index) => ({
            week,
            quantity: quantitiesPerWeek.get(week) ?? 0,
            index,
          }))

          const filteredValidWeeks = validWeeks.slice(
            validWeeks.findIndex((week) => week.quantity !== 0),
          )

          let wowGrowth: number | null = null

          if (
            filteredValidWeeks.length >= 2 &&
            filteredValidWeeks[filteredValidWeeks.length - 1].week ===
              lastFourWeeksStrings[3]
          ) {
            const earliestValid = filteredValidWeeks[0]
            const latestValid =
              filteredValidWeeks[filteredValidWeeks.length - 1]
            const diffWeeks = latestValid.index - earliestValid.index

            if (diffWeeks === 0) {
              wowGrowth = null
            } else if (
              earliestValid.quantity === 0 &&
              latestValid.quantity === 0
            ) {
              wowGrowth = 0
            } else if (earliestValid.quantity === 0) {
              wowGrowth = latestValid.quantity === 0 ? 0 : Infinity
            } else if (latestValid.quantity === 0) {
              wowGrowth = -100
            } else {
              const growthFactor =
                (latestValid.quantity! / earliestValid.quantity!) **
                (1 / diffWeeks)
              wowGrowth = (growthFactor - 1) * 100
            }
          }

          const quantityLastWeek1 =
            quantitiesPerWeek.get(lastFourWeeksStrings[3]) ?? 0
          const quantityLastWeek2 =
            quantitiesPerWeek.get(lastFourWeeksStrings[2]) ?? 0
          const quantityLastWeek3 =
            quantitiesPerWeek.get(lastFourWeeksStrings[1]) ?? 0
          const quantityLastWeek4 =
            quantitiesPerWeek.get(lastFourWeeksStrings[0]) ?? 0

          return {
            itemName: earliest.itemName,
            categoryName: earliest.categoryName,
            quantityLastWeek1: Math.round(quantityLastWeek1 * 100) / 100,
            quantityLastWeek2: Math.round(quantityLastWeek2 * 100) / 100,
            quantityLastWeek3: Math.round(quantityLastWeek3 * 100) / 100,
            quantityLastWeek4: Math.round(quantityLastWeek4 * 100) / 100,
            wowGrowth,
            id: earliest.itemName,
            parentId: 'root',
          }
        },
      )

      const filteredResult = result.filter(
        (item) =>
          item.wowGrowth !== null &&
          isFinite(item.wowGrowth) &&
          !isNaN(item.wowGrowth),
      )

      if (type === 'top') {
        const sortedResult = filteredResult.sort(
          (a, b) => (b.wowGrowth || 0) - (a.wowGrowth || 0),
        )
        const topWowGrowth = sortedResult[NUM_OF_TOP_BOTTOM - 1]?.wowGrowth
        return sortedResult.filter(
          (item, index) =>
            index < NUM_OF_TOP_BOTTOM || item.wowGrowth === topWowGrowth,
        )
      } else {
        const sortedResult = filteredResult.sort(
          (a, b) => (a.wowGrowth || 0) - (b.wowGrowth || 0),
        )
        const bottomWowGrowth = sortedResult[NUM_OF_TOP_BOTTOM - 1]?.wowGrowth
        return sortedResult.filter(
          (item, index) =>
            index < NUM_OF_TOP_BOTTOM || item.wowGrowth === bottomWowGrowth,
        )
      }
    }, [data, type]),
    loading,
  }
}

export const useTopItemWowGrowthByQuantity = (): IApiType => {
  return useItemWowGrowthByQuantity('top')
}

export const useBottomItemWowGrowthByQuantity = (): IApiType => {
  return useItemWowGrowthByQuantity('bottom')
}

export const configs = {
  topItemWowGrowthByQuantity: itemWowGrowthByQuantityConfigs,
  bottomItemWowGrowthByQuantity: itemWowGrowthByQuantityConfigs,
}

export const api = {
  topItemWowGrowthByQuantity: useTopItemWowGrowthByQuantity,
  bottomItemWowGrowthByQuantity: useBottomItemWowGrowthByQuantity,
}

export default useItemWowGrowthByQuantity
