import { IConfigsType } from '../../types'

const mwbConfig: IConfigsType = {
  mwb: {
    '/:brand/item_by_daypart': {
      variables: ['items', 'itemByDaypartColumnFilter'],
      groupFilter: {
        api: 'corporateFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      title: {
        type: 'title',
        title: 'Items by Daypart',
      },
      filters: {
        type: 'grid',
        children: {
          item: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Item',
          },
          unit: {
            type: 'select',
            value: 'itemByDaypartColumnFilter.value',
            onChange: 'itemByDaypartColumnFilter.onChange',
            options: 'itemByDaypartColumnFilter.options',
            label: 'Unit',
          },
        },
      },
      itemByDaypart: {
        type: 'table-v2',
        api: 'itemByDaypart',
        columns: [
          {
            key: 'locationName',
            title: 'Name',
            sortable: true,
          },
          {
            key: 'itemOrCategoryName',
            title: 'Item',
            align: 'center',
            sortable: true,
          },
          {
            key: 'earlyBird.percent',
            title: 'Early Bird',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'earlyBird.quantity',
            title: 'Early Bird',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'breakfast.percent',
            title: 'Breakfast',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'breakfast.quantity',
            title: 'Breakfast',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'lunch.percent',
            title: 'Lunch',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'lunch.quantity',
            title: 'Lunch',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'afternoonSnack.percent',
            title: 'Afternoon Snack',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'afternoonSnack.quantity',
            title: 'Afternoon Snack',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'dinner.percent',
            title: 'Dinner',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'dinner.quantity',
            title: 'Dinner',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
          {
            key: 'eveningSnack.percent',
            title: 'Evening Snack',
            sortable: true,
            align: 'center',
            decimal: 1,
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "percent" %>',
          },
          {
            key: 'eveningSnack.quantity',
            title: 'Evening Snack',
            sortable: true,
            align: 'center',
            hide: '<%- itemByDaypartColumnFilter.value.slice(-1)[0]?.[0] !== "quantity" %>',
          },
        ],
      },
    },
    '/:brand/item_velocity': {
      variables: ['items', 'interval', 'timeBetween'],
      groupFilter: {
        api: 'companyFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      title: {
        type: 'title',
        title: 'Item Velocity',
      },
      filters: {
        type: 'grid',
        children: {
          items: {
            type: 'select',
            value: 'items.value',
            onChange: 'items.onChange',
            options: 'items.options',
            label: 'Show',
          },
          intervals: {
            type: 'select',
            value: 'interval.value',
            onChange: 'interval.onChange',
            options: 'interval.options',
            label: 'Time Interval',
          },
          timeBetween: {
            type: 'select',
            value: 'timeBetween.value',
            onChange: 'timeBetween.onChange',
            options: 'timeBetween.options',
            label: 'Minutes Between Orders',
          },
        },
      },
      itemVelocitySummary: {
        type: 'list',
        api: 'madeFromScratchSummary',
        fields: [
          {
            key: 'value',
            title: 'Total Incidences',
            width: '150px',
          },
          {
            key: 'percentScratch',
            title: '% Affected Customers who ordered Item',
            decimal: 1,
            width: '200px',
          },
        ],
      },
      itemVelocityCsv: {
        type: 'download-csv',
        api: 'madeFromScratch',
        message: 'Download as CSV',
        fileName: 'getexpo-item-velocity',
        fields: [
          {
            key: 'daypart',
            title: 'Time',
          },
          {
            key: 'value',
            title: 'Made From Scratch #',
          },
          {
            key: 'percentScratch',
            title: 'Made From Scratch %',
          },
          {
            key: 'total',
            title: 'Total Times Ordered',
          },
        ],
      },
      itemVelocity: {
        type: 'table-v2',
        api: 'madeFromScratch',
        columns: [
          {
            key: 'daypart',
            title: 'Time',
            sortable: true,
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
          },
          {
            key: 'madeFromScratch',
            title: 'Made From Scratch',
            align: 'center',
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
            children: [
              {
                key: 'value',
                title: '#',
                sortable: true,
                align: 'center',
              },
              {
                key: 'percentScratch',
                title: '%',
                sortable: true,
                align: 'center',
                decimal: 1,
              },
            ],
          },
          {
            key: 'total',
            title: 'Total Times Ordered',
            sortable: true,
            align: 'center',
            background:
              '<%- values?.daypart === "Average Per Day" ? "#57AFE7" : "initial" %>',
          },
        ],
      },
    },
  },
  'mwb-product-summary-eaa772ee': {
    '/:brand/product_summary': {
      variables: ['date'],
      groupFilter: {
        api: 'companyFilter',
      },
      dateFilter: {
        types: ['week'],
      },
      title: {
        type: 'title',
        title: 'Summary',
      },
      topItemWowGrowthByQuantity: {
        type: 'table-v2',
        api: 'topItemWowGrowthByQuantity',
        title: 'Top Trending Items by Quantity Sold',
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: false,
          },
          {
            key: 'categoryName',
            title: 'Category',
            sortable: false,
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
            align: 'center',
            children: [
              {
                key: 'quantityLastWeek4',
                title: '<%- date?.getInfo(-4)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'quantityLastWeek3',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'quantityLastWeek2',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'quantityLastWeek1',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: false,
              },
            ],
          },
          {
            key: 'wowGrowth',
            title: 'WoW Growth',
            sortable: false,
            align: 'center',
          },
        ],
      },
      bottomItemWowGrowthByQuantity: {
        type: 'table-v2',
        api: 'bottomItemWowGrowthByQuantity',
        title: 'Bottom Trending Items by Quantity Sold',
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: false,
          },
          {
            key: 'categoryName',
            title: 'Category',
            sortable: false,
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
            align: 'center',
            children: [
              {
                key: 'quantityLastWeek4',
                title: '<%- date?.getInfo(-4)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'quantityLastWeek3',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'quantityLastWeek2',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'quantityLastWeek1',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: false,
              },
            ],
          },
          {
            key: 'wowGrowth',
            title: 'WoW Growth',
            sortable: false,
            align: 'center',
          },
        ],
      },
      topItemWowGrowthBySales: {
        type: 'table-v2',
        api: 'topItemWowGrowthBySales',
        title: 'Top Trending Items by BP of Sales',
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: false,
          },
          {
            key: 'categoryName',
            title: 'Category',
            sortable: false,
          },
          {
            key: 'itemSales',
            title: 'BP of Sales',
            align: 'center',
            children: [
              {
                key: 'salesLastWeek4',
                title: '<%- date?.getInfo(-4)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'salesLastWeek3',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'salesLastWeek2',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'salesLastWeek1',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: false,
              },
            ],
          },
          {
            key: 'wowGrowth',
            title: 'WoW Growth',
            sortable: false,
            align: 'center',
          },
        ],
      },
      bottomItemWowGrowthBySales: {
        type: 'table-v2',
        api: 'bottomItemWowGrowthBySales',
        title: 'Bottom Trending Items by BP of Sales',
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: false,
          },
          {
            key: 'categoryName',
            title: 'Category',
            sortable: false,
          },
          {
            key: 'itemSales',
            title: 'BP of Sales',
            align: 'center',
            children: [
              {
                key: 'salesLastWeek4',
                title: '<%- date?.getInfo(-4)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'salesLastWeek3',
                title: '<%- date?.getInfo(-3)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'salesLastWeek2',
                title: '<%- date?.getInfo(-2)?.displayName %>',
                align: 'center',
                sortable: false,
              },
              {
                key: 'salesLastWeek1',
                title: '<%- date?.getInfo(-1)?.displayName %>',
                align: 'center',
                sortable: false,
              },
            ],
          },
          {
            key: 'wowGrowth',
            title: 'WoW Growth',
            sortable: false,
            align: 'center',
          },
        ],
      },
    },
    '/:brand/trending_leaderboard': {
      variables: ['date'],
      groupFilter: {
        api: 'companyFilter',
      },
      dateFilter: {
        types: [
          'week',
          'period',
          'quarter',
          'year',
          'last_week',
          'this_week',
          'yesterday',
          'trailing_7_days',
          'trailing_90_days',
          'custom',
        ],
      },
      title: {
        type: 'title',
        title: 'Trending Leaderboard',
      },
      trendingLeaderboard: {
        type: 'table-v2',
        api: 'item',
        title: '',
        columns: [
          {
            key: 'itemName',
            title: 'Item',
            sortable: true,
          },
          {
            key: 'displayParentCategoryName',
            title: 'Category',
            sortable: true,
          },
          {
            key: 'quantitySold',
            title: 'Quantity Sold',
            align: 'center',
            sortable: true,
          },
          {
            key: 'totalSales',
            title: 'Item Sales',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkCount',
            title: 'Check Count',
            align: 'center',
            sortable: true,
          },
          {
            key: 'percentOfChecks',
            title: '% of Checks',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkTotal',
            title: 'Check Total',
            align: 'center',
            sortable: true,
          },
          {
            key: 'checkAverage',
            title: 'Check Average',
            align: 'center',
            sortable: true,
          },
        ],
      },
    },
  },
}

export default mwbConfig
