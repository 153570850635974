import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiType } from '../../types'
import buildMetricValuesHook from '../utils/buildMetricValuesHook'

const itemQuery = gql`
  query listItemMetricValues(
    $iStartDate: Date!
    $iEndDate: Date!
    $iQueryType: String!
    $iFilter: JSON!
  ) {
    listItemMetricValues(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iQueryType: $iQueryType
      iFilter: $iFilter
    ) {
      nodes {
        itemName
        displayParentCategoryName
        metricData
      }
    }
  }
`

export const itemConfigs = {
  itemName: 'string',
  displayParentCategoryName: 'string',
  quantitySold: 'number',
  totalSales: 'price',
  checkCount: 'number',
  checkTotal: 'price',
  checkAverage: 'price',
  percentOfChecks: 'percent',
} as const

type ListItemMetricValue = {
  itemName: string
  displayParentCategoryName: string
  metricData?: Record<string, { unit: string; value: number }>
}

const useItem = (): IApiType => {
  const { startDate, endDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const metricCodes = [
    'quantity_sold',
    'total_sales',
    'check_count',
    'check_total',
    'check_average',
  ]

  const itemFilter = {
    item_codes: ['ALL ITEMS'],
    metrics: metricCodes,
    location_group_ids: groupFilter?.ids,
  }

  const { data: itemData, loading: itemLoading } = useQuery(itemQuery, {
    variables: {
      iStartDate: startDate,
      iEndDate: endDate,
      iQueryType: 'ITEM',
      iFilter: itemFilter,
    },
    skip: !startDate || !endDate || !groupFilter || metricCodes.length === 0,
  })

  const useLocationMetrics = buildMetricValuesHook({
    groupFilterType: 'corporate',
    metrics: { codes: ['check_count'] },
  })

  const { data: locationData, loading: locationLoading } = useLocationMetrics()

  const loading = itemLoading || locationLoading

  return {
    data: useMemo(() => {
      if (!itemData || !locationData) return null

      const listItemMetricValues = (itemData.listItemMetricValues?.nodes ||
        []) as ListItemMetricValue[]

      let totalCheckCount = 0
      locationData.forEach((n) => {
        totalCheckCount += typeof n.checkCount === 'number' ? n.checkCount : 0
      })

      return listItemMetricValues
        .map((n) => {
          const quantitySold = n.metricData?.['quantity_sold']?.value || 0
          const totalSales = n.metricData?.['total_sales']?.value || 0
          const checkCount = n.metricData?.['check_count']?.value || 0
          const checkTotal = n.metricData?.['check_total']?.value || 0
          const checkAverage = n.metricData?.['check_average']?.value || 0
          const percentOfChecks =
            totalCheckCount > 0 ? (checkCount / totalCheckCount) * 100 : 0

          return {
            itemName: n.itemName,
            displayParentCategoryName: n.displayParentCategoryName,
            quantitySold,
            totalSales,
            checkCount,
            checkTotal,
            checkAverage,
            percentOfChecks,
            id: n.itemName,
            parentId: 'root',
          }
        })
        .sort((a, b) => b.checkCount - a.checkCount)
    }, [itemData, locationData]),
    loading,
  }
}

export default useItem
