import { gql, useQuery } from '@apollo/client'
import { useMemo } from 'react'

import { useDateFilter } from '../../../dateFilter'
import { useGroupFilter } from '../../../groupFilter'
import { IApiType } from '../../types'

const NUM_OF_TOP_BOTTOM = 10

const query = gql`
  query trendLocationGroupItemMetrics(
    $iStartDate: Date!
    $iEndDate: Date!
    $iFilter: JSON!
  ) {
    trendLocationGroupItemMetrics(
      iStartDate: $iStartDate
      iEndDate: $iEndDate
      iFilter: $iFilter
      iGroupBy: "business_week"
    ) {
      nodes {
        locationGroupId
        locationGroupName
        startDate
        endDate
        itemCode
        itemName
        categoryName
        itemSales
        percentOfSales
        businessWeek
        businessWeekOfMonth
        businessMonth
        businessQuarter
        businessYear
      }
    }
  }
`

export const itemWowGrowthBySalesConfigs = {
  locationGroupId: 'number',
  locationGroupName: 'string',
  startDate: 'string',
  endDate: 'string',
  itemCode: 'string',
  itemName: 'string',
  categoryName: 'string',
  itemSales: 'price',
  percentOfSales: 'percent',
  wowGrowth: 'percent',
  salesLastWeek1: 'string',
  salesLastWeek2: 'string',
  salesLastWeek3: 'string',
  salesLastWeek4: 'string',
  businessWeek: 'number',
  businessWeekOfMonth: 'number',
  businessMonth: 'number',
  businessQuarter: 'number',
  businessYear: 'number',
} as const

const useItemWowGrowthBySales = (type: 'top' | 'bottom'): IApiType => {
  const { startDate } = useDateFilter()
  const { groupFilter } = useGroupFilter()
  const iFilter = {
    location_group_ids: groupFilter?.ids,
    metrics: ['quantity_sold'],
  }

  const queryStartDate = startDate ? new Date(startDate) : new Date()
  queryStartDate.setDate(queryStartDate.getDate() - 28)
  const queryEndDate = startDate ? new Date(startDate) : new Date()
  queryEndDate.setDate(queryEndDate.getDate() - 1)

  const { data, loading } = useQuery(query, {
    variables: {
      iStartDate: queryStartDate.toISOString().split('T')[0],
      iEndDate: queryEndDate.toISOString().split('T')[0],
      iFilter,
    },
    skip: !startDate || !groupFilter,
  })

  return {
    data: useMemo(() => {
      const nodes = data?.trendLocationGroupItemMetrics?.nodes as
        | {
            locationGroupId: number
            locationGroupName: string
            startDate: string
            endDate: string
            itemCode: string
            itemName: string
            categoryName: string
            itemSales: number | null
            percentOfSales: number | null
            businessWeek: number
            businessWeekOfMonth: number
            businessMonth: number
            businessQuarter: number
            businessYear: number
          }[]
        | undefined

      if (!nodes) return null

      const allWeeks = Array.from(new Set(nodes.map((node) => node.startDate)))
        .map((dateStr) => new Date(dateStr))
        .sort((a, b) => a.getTime() - b.getTime())

      const lastFourWeeksDates = allWeeks.slice(-4)
      const lastFourWeeksStrings = lastFourWeeksDates.map(
        (date) => date.toISOString().split('T')[0],
      )

      const itemMap = new Map<
        string,
        {
          earliest: typeof nodes[0]
          latest: typeof nodes[0]
          salesPerWeek: Map<string, number>
        }
      >()

      nodes.forEach((node) => {
        const key = node.itemName
        const dateStr = node.startDate
        const current = itemMap.get(key)
        if (!current) {
          const salesPerWeek = new Map<string, number>()
          salesPerWeek.set(dateStr, node.percentOfSales ?? 0)
          itemMap.set(key, {
            earliest: node,
            latest: node,
            salesPerWeek,
          })
        } else {
          const earliestDate = new Date(current.earliest.startDate)
          const latestDate = new Date(current.latest.startDate)
          const nodeDate = new Date(node.startDate)
          if (nodeDate < earliestDate) current.earliest = node
          if (nodeDate > latestDate) current.latest = node
          const existingSales = current.salesPerWeek.get(dateStr) || 0
          current.salesPerWeek.set(
            dateStr,
            existingSales + (node.percentOfSales ?? 0),
          )
        }
      })

      const result = Array.from(itemMap.values()).map(
        ({ earliest, latest, salesPerWeek }) => {
          let validWeeks = lastFourWeeksStrings.map((week, index) => ({
            week,
            sales: salesPerWeek.get(week) ?? 0,
            index,
          }))
          validWeeks = validWeeks.slice(
            validWeeks.findIndex((week) => week.sales !== 0),
          )

          let wowGrowth = null

          if (
            validWeeks.length >= 2 &&
            validWeeks[validWeeks.length - 1].week === lastFourWeeksStrings[3]
          ) {
            const earliestValid = validWeeks[0]
            const latestValid = validWeeks[validWeeks.length - 1]
            const diffWeeks = latestValid.index - earliestValid.index

            if (diffWeeks === 0) {
              wowGrowth = null
            } else if (earliestValid.sales === 0 && latestValid.sales === 0) {
              wowGrowth = 0
            } else if (earliestValid.sales === 0) {
              wowGrowth = latestValid.sales === 0 ? 0 : Infinity
            } else if (latestValid.sales === 0) {
              wowGrowth = -100
            } else {
              const growthFactor =
                (latestValid.sales / earliestValid.sales) ** (1 / diffWeeks)
              wowGrowth = (growthFactor - 1) * 100
            }
          }

          const salesLastWeek1 = salesPerWeek.get(lastFourWeeksStrings[3]) ?? 0
          const salesLastWeek2 = salesPerWeek.get(lastFourWeeksStrings[2]) ?? 0
          const salesLastWeek3 = salesPerWeek.get(lastFourWeeksStrings[1]) ?? 0
          const salesLastWeek4 = salesPerWeek.get(lastFourWeeksStrings[0]) ?? 0

          return {
            itemName: earliest.itemName,
            categoryName: earliest.categoryName,
            salesLastWeek1: Math.round(salesLastWeek1 * 10000) / 100,
            salesLastWeek2: Math.round(salesLastWeek2 * 10000) / 100,
            salesLastWeek3: Math.round(salesLastWeek3 * 10000) / 100,
            salesLastWeek4: Math.round(salesLastWeek4 * 10000) / 100,
            wowGrowth,
            id: earliest.itemName,
            parentId: 'root',
          }
        },
      )

      const filteredResult = result.filter(
        (item) =>
          item.wowGrowth !== null &&
          isFinite(item.wowGrowth) &&
          !isNaN(item.wowGrowth),
      )

      if (type === 'top') {
        const sortedResult = filteredResult.sort(
          (a, b) => (b.wowGrowth || 0) - (a.wowGrowth || 0),
        )
        const topWowGrowth = sortedResult[NUM_OF_TOP_BOTTOM - 1]?.wowGrowth
        return sortedResult.filter(
          (item, index) =>
            index < NUM_OF_TOP_BOTTOM || item.wowGrowth === topWowGrowth,
        )
      } else {
        const sortedResult = filteredResult.sort(
          (a, b) => (a.wowGrowth || 0) - (b.wowGrowth || 0),
        )
        const bottomWowGrowth = sortedResult[NUM_OF_TOP_BOTTOM - 1]?.wowGrowth
        return sortedResult.filter(
          (item, index) =>
            index < NUM_OF_TOP_BOTTOM || item.wowGrowth === bottomWowGrowth,
        )
      }
    }, [data, type]),
    loading,
  }
}

export const useTopItemWowGrowthBySales = (): IApiType => {
  return useItemWowGrowthBySales('top')
}

export const useBottomItemWowGrowthBySales = (): IApiType => {
  return useItemWowGrowthBySales('bottom')
}

export const configs = {
  topItemWowGrowthBySales: itemWowGrowthBySalesConfigs,
  bottomItemWowGrowthBySales: itemWowGrowthBySalesConfigs,
}

export const api = {
  topItemWowGrowthBySales: useTopItemWowGrowthBySales,
  bottomItemWowGrowthBySales: useBottomItemWowGrowthBySales,
}

export default useItemWowGrowthBySales
